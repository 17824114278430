import React, { useEffect } from "react";
import { FormControl, Typography, Chip, Select, InputLabel, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useSelector } from "react-redux";

export default function DDMatchingQAReport({ children, displayHeader, taskConfig, setTaskConfig, submitHandler, studyType, setIsRequired }) {
  const productionCycles = useSelector((state) => state.formOptions.productionCycle);
  useEffect(() => {
    setTaskConfig((preConfig) => ({
      full_match_qa: true,
      sales_aggregate_client_match: true,
      split_broker_only_match: true,
      ...preConfig,
      study: studyType,
    }));
    setIsRequired((prevState) => ([...prevState, "production_cycle"]));
  }, [setTaskConfig, studyType, setIsRequired]);

  const onCheckHandler = (e) => {
    setTaskConfig((prevTaskConfig) => ({
      ...prevTaskConfig,
      [e.target.value]: e.target.checked,
    }));
  };

  return (
    <Form
      schema={{}}
      onSubmit={submitHandler}
      validator={validator}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ marginRight: "8px", marginBottom: "16px" }}>Task Name :</Typography>
        <Chip style={{ marginBottom: "16px" }} variant="outlined" label={displayHeader(["file_type"])} />
      </div>
      <Typography variant="h4" style={{ backgroundColor: "orange", marginBottom: "10px" }}>Discovery Data</Typography>
      <FormControl fullWidth variant="outlined">
        <InputLabel required htmlFor="inputLabel-production-cycle-select">Production Cycle</InputLabel>
        <Select
          required
          style={{ width: 500, marginBottom: 20 }}
          value={taskConfig.production_cycle || ""}
          onChange={(e) => {
            setTaskConfig((prevTaskConfig) => ({ ...prevTaskConfig, production_cycle: e.target.value }));
          }}
          label="Production Cycle"
          inputProps={{
            name: "production cycle",
            id: "inputLabel-production-cycle-select",
          }}
        >
          {productionCycles.map((quarter) => (
            <MenuItem key={quarter} value={quarter}>
              {quarter}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{
        display: "flex",
        flexFlow: "column",
      }}
      >
        <FormControl margin="normal" variant="outlined">
          <FormControlLabel
            control={(
              <Checkbox
                value="full_match_qa"
                onChange={onCheckHandler}
                checked={taskConfig.full_match_qa ?? true}
              />
            )}
            label="Full Match QA Sheet (8Q)"
          />
        </FormControl>
        <FormControl margin="normal" variant="outlined">
          <FormControlLabel
            control={(
              <Checkbox
                value="sales_aggregate_client_match"
                onChange={onCheckHandler}
                checked={taskConfig.sales_aggregate_client_match ?? true}
              />
            )}
            label="SALES AGG By Client Match QA Sheet (4Q)"
          />
        </FormControl>
        <FormControl margin="normal" variant="outlined">
          <FormControlLabel
            control={(
              <Checkbox
                value="split_broker_only_match"
                onChange={onCheckHandler}
                checked={taskConfig.split_broker_only_match ?? true}
              />
            )}
            label="Split Broker Only Match Sheet (4Q)"
          />
        </FormControl>
      </div>
      {children}
    </Form>
  );
}
